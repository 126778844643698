import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton, IconServices } from '../../components';
import classNames from 'classnames';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ListingPage.module.css';
const { Money } = sdkTypes;

const OPTIONS_LIMIT = 2;

const SectionAdditionalServices = ({
  className,
  rootClassName,
  publicData,
  intl,
  handleFetchTransactionLineItems,
  selectedServices,
}) => {
  const classes = classNames(rootClassName || css.section, className);
  const unitTypeDay = publicData.mainCategory !== 'sleep';

  let selectedOptions =
    !!publicData?.additionalServices && publicData.additionalServices.filter(r => !!r.selected);

  if (!publicData?.additionalServices) {
    return null;
  }

  const [showFullContent, setShowFullContent] = useState(false);
  const showAll = () => setShowFullContent(true);

  const addService = service => () => {
    const existService = selectedServices.find(serv => serv.title === service.title);

    if (!existService) {
      const services = [...selectedServices, service];
      handleFetchTransactionLineItems(services);
    }
  };

  const removeService = service => () => {
    const existService = selectedServices.find(serv => serv.title === service.title);

    if (existService) {
      const services = selectedServices.filter(serv => serv.title !== service.title);
      handleFetchTransactionLineItems(services);
    }
  };

  const isSelectedService = serv =>
    !!selectedServices.find(service => service.title === serv.title);

  return (
    <div className={classes}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.additionalServicesTitle" />
      </h2>
      <ul className={css.services}>
        {!showFullContent && selectedOptions?.length > OPTIONS_LIMIT
          ? selectedOptions.slice(0, OPTIONS_LIMIT).map(serv => {
              const priceValue = serv.pricePerDay || serv.pricePerItem;
              const priceMoney = new Money(+priceValue * 100, config.currency);
              return (
                <li key={serv.title} className={css.servicesItem}>
                  <div className={css.servicesTitle}>
                    <IconServices servicesType={serv.icon} />
                    <span>{serv.title}</span>
                  </div>
                  <div className={css.servicesPrice}>
                    <span>
                      {formatMoney(intl, priceMoney)}
                      <span className={css.servicesPricePerDesc}>
                        {!!serv.pricePerDay ? (
                          <FormattedMessage
                            id={`ListingPage.${unitTypeDay ? 'perDay' : 'perNight'}`}
                          />
                        ) : (
                          <FormattedMessage id="ListingPage.perItem" />
                        )}
                      </span>
                    </span>
                    <InlineTextButton className={css.addBtn} onClick={addService(serv)}>
                      <FormattedMessage id="ListingPage.addBtn" />
                    </InlineTextButton>
                  </div>
                </li>
              );
            })
          : selectedOptions.map(serv => {
              const priceValue = serv.pricePerDay || serv.pricePerItem;
              const priceMoney = new Money(+priceValue * 100, config.currency);
              return (
                <li key={serv.title} className={css.servicesItem}>
                  <div className={css.servicesTitle}>
                    <IconServices servicesType={serv.icon} />
                    <span>{serv.title}</span>
                  </div>
                  <div className={css.servicesPrice}>
                    <span>
                      {formatMoney(intl, priceMoney)}
                      <span className={css.servicesPricePerDesc}>
                        {!!serv.pricePerDay ? (
                          <FormattedMessage
                            id={`ListingPage.${unitTypeDay ? 'perDay' : 'perNight'}`}
                          />
                        ) : (
                          <FormattedMessage id="ListingPage.perItem" />
                        )}
                      </span>
                    </span>
                    {isSelectedService(serv) ? (
                      <InlineTextButton className={css.removeBtn} onClick={removeService(serv)}>
                        <FormattedMessage id="ListingPage.removeBtn" />
                      </InlineTextButton>
                    ) : (
                      <InlineTextButton className={css.addBtn} onClick={addService(serv)}>
                        <FormattedMessage id="ListingPage.addBtn" />
                      </InlineTextButton>
                    )}
                  </div>
                </li>
              );
            })}
        {!showFullContent && selectedOptions?.length > OPTIONS_LIMIT && (
          <div className={classNames(css.seeMore, css.seeMoreCenter)} onClick={() => showAll()}>
            <FormattedMessage id="ListingPage.seeMore" />
          </div>
        )}
      </ul>
    </div>
  );
};

SectionAdditionalServices.defaultProps = { className: null, rootClassName: null };

SectionAdditionalServices.propTypes = {
  className: string,
  rootClassName: string,
};

export default SectionAdditionalServices;
