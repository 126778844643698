import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCheckmarkAlt } from '../../components';
import { languagesHelper as language } from '../../helpers/languages';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const SectionRulesMaybe = props => {
  const { className, rootClassName, publicData } = props;
  const classes = classNames(rootClassName || css.section, className);
  const locale = language.get();
  
  const selectedRules = !!publicData?.extraRules && publicData.extraRules.filter(r => !!r.selected?.length)
  
  if(!selectedRules?.length) {
    return null
  }
  
  return  (
    <div className={classes}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h2>
      <ul className={css.rules}>
        {selectedRules.map(rule => {
          return (
            <li key={rule.title} className={css.rulesItem}>
              <IconCheckmarkAlt />
              {locale === 'pl' ? rule.titlePl : rule.title}
            </li>
          )
        })}
      </ul>
    </div>
  );
};

SectionRulesMaybe.defaultProps = { className: null, rootClassName: null };

SectionRulesMaybe.propTypes = {
  className: string,
  rootClassName: string,
};

export default SectionRulesMaybe;
