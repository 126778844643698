import React from 'react';
import { bool, func } from 'prop-types';
import classNames from 'classnames';

import css from './ListingPage.module.css';


const LikeIcon = ({isFavourite}) => {
  const iconClasses = classNames(css.likeIcon, {[css.likeIconFavourite]: isFavourite});

  return (
    <svg className={iconClasses} width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.434 4.67956L14.1532 5.37564L14.8486 4.65582C15.0285 4.46962 15.2008 4.2892 15.3672 4.11486C16.9896 2.41546 18.0597 1.29458 20.2798 1.04006C22.6024 0.777912 24.8534 1.82817 26.223 3.5419C27.5738 5.23211 28.0393 7.52485 26.8764 9.82622L26.8763 9.82645C26.1794 11.2066 24.7055 12.9317 22.9638 14.7188L22.9636 14.719C21.7672 15.9472 20.518 17.1332 19.3749 18.2185C18.6908 18.8679 18.0448 19.4813 17.4707 20.0461L14.1213 23.3409L11.4834 20.8232C11.4833 20.8232 11.4832 20.8231 11.4832 20.823C10.8089 20.1792 10.0885 19.5279 9.35008 18.8605C7.80031 17.4596 6.17167 15.9874 4.72647 14.3629C2.60907 11.9829 1.10706 9.50056 1.00396 6.86236C0.882208 3.34431 3.6451 1.0294 7.01575 1.07171C8.54024 1.09227 9.57422 1.47028 10.4836 2.06375C11.3 2.59649 12.0168 3.29669 12.9189 4.17795C13.0838 4.33908 13.2549 4.50625 13.434 4.67956Z" stroke="#2F4E1E" strokeWidth="2"/>
    </svg>
  )
}

const AddToFavourites = ({ handleAddToFavourites, isFavourite }) => {
  return (
    <div className={css.likeIconButton} onClick={handleAddToFavourites}>
      <LikeIcon isFavourite={isFavourite} />
    </div>
  )
}


AddToFavourites.defaultProps = {
  isFavourite: false,
};

AddToFavourites.propTypes = {
  isFavourite: bool.isRequired,
  handleAddToFavourites: func.isRequired
};

export default AddToFavourites;
