import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map, IconMapMarker } from '../../components';
import config from '../../config';
import css from './ListingPage.module.css';


class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: false };
  }

  render() {
    const {
      className,
      rootClassName,
      geolocation,
      publicData,
      listingId,
      listingMainCategory,
      listingsforMap,
    } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.section, css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} listingMainCategory={listingMainCategory} listings={listingsforMap} />;

    const { CAT_SLEEP, CAT_RENT, CAT_ACTIVITIES } = config.custom;

    return (
      <div className={classes}>
        <h2 className={css.sectionTitle}>
          <FormattedMessage id="ListingPage.locationTitle" />
        </h2>
        <div className={css.locationDescriptionHolder}>
          {address && (
            <p className={css.locationDescription}>
              {address}
              {/* <FormattedMessage id="ListingPage.locationDescription" /> */}
            </p>
          )}

          <div className={css.legendHolder}>
            <IconMapMarker rootClassName={css.legendItem} markerCategory={CAT_SLEEP} showMarkerLabel />
            <IconMapMarker rootClassName={css.legendItem} markerCategory={CAT_RENT} showMarkerLabel />
            <IconMapMarker rootClassName={css.legendItem} markerCategory={CAT_ACTIVITIES} showMarkerLabel />
          </div>
        </div>


        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => {
              this.setState({ isStatic: false });
            }}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
