import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconCheckmarkAlt } from '../../components';
import { languagesHelper as language } from '../../helpers/languages';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const OPTIONS_LIMIT = 2;

const SectionSustainablePractices = props => {
  const { className, rootClassName, publicData } = props;
  const classes = classNames(rootClassName || css.section, className);
  const locale = language.get();
  
  let selectedRules = !!publicData?.sustainablePractices && publicData.sustainablePractices.filter(r => !!r.selected?.length)
  
  if (!selectedRules?.length) {
    return null
  }

  const [showFullContent, setShowFullContent] = useState(false)

  const showAll = () => {
    setShowFullContent(true)
  }

  return (
    <div className={classes}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.sustainablePracticesTitle" />
      </h2>
      <ul className={css.rules}>
        {!showFullContent && selectedRules?.length > OPTIONS_LIMIT ? selectedRules.slice(0, OPTIONS_LIMIT).map(rule => {
          return (
            <li key={rule.title} className={css.rulesItem}>
              <IconCheckmarkAlt />
              {locale === 'pl' ? rule.titlePl : rule.title}
            </li>
          )
        }) : selectedRules.map(rule => {
          return (
            <li key={rule.title} className={css.rulesItem}>
              <IconCheckmarkAlt />
              {locale === 'pl' ? rule.titlePl : rule.title}
            </li>
          )
        })}
        {!showFullContent && selectedRules?.length > OPTIONS_LIMIT && (
          <div className={css.seeMore} onClick={() => showAll()}>
            <FormattedMessage id="ListingPage.seeMore" />
          </div>
        )}
      </ul>
    </div>
  );
};

SectionSustainablePractices.defaultProps = { className: null, rootClassName: null };

SectionSustainablePractices.propTypes = {
  className: string,
  rootClassName: string,
};

export default SectionSustainablePractices;
