import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews, IconReviewStar } from '../../components';

import css from './ListingPage.module.css';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, reviewsAverageRating } = props;

  if (!reviews?.length) {
    return null
  }

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  return (
    <div className={css.section}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.reviewsHeading" />
      </h2>
      {!!reviews?.length && (
        <div className={css.authorInfoItem}>
          <IconReviewStar isFilled />
          <span className={css.reviewInfoRating}>
            {reviewsAverageRating}
          </span>
          <span className={css.reviewInfoTotal}>
            {reviews?.length === 1 ? (
              <FormattedMessage id="ListingPage.reviewCounter" values={{ reviewsCounter: reviews?.length }} />
            ) : (
              <FormattedMessage id="ListingPage.reviewsCounter" values={{ reviewsCounter: reviews?.length }} />
            )}
          </span>
        </div>
      )}
      {fetchReviewsError ? reviewsError : null}
      <Reviews reviews={reviews} />
    </div>
  );
};

export default SectionReviews;
