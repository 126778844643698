import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import config from '../../config';
import css from './ListingPage.module.css';

const SectionFeaturesMaybe = props => {
  const { publicData, intl } = props;

  if (!publicData?.standartAmenities || !publicData?.extrasAmenities || !publicData?.terrainAmenities) {
    return null;
  }

  const optionsStandarLabel = intl.formatMessage({
    id: 'ListingPage.standart',
  });
  const optionsExtrasLabel = intl.formatMessage({
    id: 'ListingPage.extras',
  });
  const optionsTerrainLabel = intl.formatMessage({
    id: 'ListingPage.terrain',
  });

  const optionsStandar = config.custom.standartAmenitiesOptions;
  const optionsExtras = config.custom.extrasAmenitiesOptions;
  const optionsTerrain = config.custom.terrainAmenitiesOptions;

  const selectedOptionsStandart = publicData?.standartAmenities || [];
  const selectedOptionsExtras = publicData?.extrasAmenities || [];
  const selectedOptionsTerrain = publicData?.terrainAmenities || [];

  return (
    <div className={css.section}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>

      <div className={css.featuresHolder}>
        <PropertyGroup
          id="ListingPage.standartAmenities"
          options={optionsStandar}
          selectedOptions={selectedOptionsStandart}
          label={optionsStandarLabel}
          rootClassName={css.featuresItem}
          showMoreBtn
          showIcon
          withTranslations
          intl={intl}
        />
        <PropertyGroup
          id="ListingPage.extrasAmenities"
          options={optionsExtras}
          selectedOptions={selectedOptionsExtras}
          label={optionsExtrasLabel}
          rootClassName={css.featuresItem}
          showMoreBtn
          showIcon
          withTranslations
          intl={intl}
        />
        <PropertyGroup
          id="ListingPage.terrainAmenities"
          options={optionsTerrain}
          selectedOptions={selectedOptionsTerrain}
          label={optionsTerrainLabel}
          rootClassName={css.featuresItem}
          showMoreBtn
          showIcon
          withTranslations
          intl={intl}
        />
      </div>
    </div>
  );
};

export default SectionFeaturesMaybe;
