import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
// import { richText } from '../../util/richText';
import { ContentWithShowMore } from '../../components';

import css from './ListingPage.module.css';

// const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SHORT_CONTENT_LENGTH = 291;

const SectionDescriptionMaybe = props => {
  const { description } = props;

  return (
    description && (
      <div className={css.section}>
        <h2 className={css.sectionTitle}>
          <FormattedMessage id="ListingPage.descriptionTitle" />
        </h2>
        <p className={css.description}>
          <ContentWithShowMore content={description} contentLimit={SHORT_CONTENT_LENGTH} showDots />
          {/* {richText(description, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })} */}
        </p>
      </div>
    )
  );
};

export default SectionDescriptionMaybe;
