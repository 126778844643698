import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';
import config from '../../config';
import css from './ListingPage.module.css';

const SectionLanguagesMaybe = props => {
  const { publicData, intl } = props;
  if (!publicData?.hostLanguage) {
    return null;
  }

  const selectedOptions = publicData?.hostLanguage || [];

  const options = config.custom.hostLanguageOptions;

  return (
    <div className={css.section}>
      <h2 className={css.sectionTitle}>
        <FormattedMessage id="ListingPage.languagesTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.hostLanguage"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
        withTranslations
        intl={intl}
      />
    </div>
  );
};

export default SectionLanguagesMaybe;
