import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';
// import debounce from 'lodash/debounce';
import {
  sendEnquiry,
  fetchTransactionLineItems,
  setInitialValues,
  addToFavourites,
  getAllListings,
  getAllListingsForMap,
  clearBookingData,
} from './ListingPage.duck';

// import SectionHeaderImages from './SectionHeaderImages';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionLanguagesMaybe from './SectionLanguagesMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
// import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionSustainablePractices from './SectionSustainablePractices';
import SectionAdditionalServices from './SectionAdditionalServices';
import SectionMapMaybe from './SectionMapMaybe';
import SectionSuggestions from './SectionSuggestions';
// import SectionWeekendPriceMaybe from './SectionWeekendPriceMaybe';
import { languagesHelper as language } from '../../helpers/languages';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const MODAL_BREAKPOINT = 768;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key, intl) => {
  const cat = categories.find(c => c.key === key);
  return cat ? language.labelsTranslator(cat.key, intl) : key;
};

const { CAT_SLEEP } = config.custom;

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      currentImageIndex: 0,
      currentBrowserGeolocation: {},
      showSuggestions: false,
      suggestionsTab: CAT_SLEEP,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);

    this.onSuggestionsTabHandle = this.onSuggestionsTabHandle.bind(this);
    this.handleGetAllListings = this.handleGetAllListings.bind(this);
  }

  handleGetAllListings() {
    if (!!window && window?.pageYOffset > 300) {
      if (this.state.showSuggestions === false) {
        this.setState({ showSuggestions: true });
        this.props.getAllListings(this.state.suggestionsTab);
        this.props.getAllListingsForMap();
      } else {
        return null;
      }
    }
  }

  componentDidMount() {
    const success = pos => {
      let crd = pos.coords;

      this.setState({
        currentBrowserGeolocation: {
          lat: crd.latitude,
          lng: crd.longitude,
        },
      });
    };

    !!navigator && navigator.geolocation.getCurrentPosition(success);

    document.addEventListener('scroll', this.handleGetAllListings);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleGetAllListings);

    const { onClearBookingData } = this.props;
    onClearBookingData({ bookingData: {}, guests: {}, additionServices: [] });
  }

  onSuggestionsTabHandle(tab) {
    this.setState({ suggestionsTab: tab });
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      // unitType,
      // isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      // sendEnquiryInProgress,
      // sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      // filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      listings,
      listingsforMap,
      onAddToFavourites,
      bookingData,
      guests,
      additionServices,
      totalPriceLineItems,
    } = this.props;
    let favourites = currentUser?.attributes?.profile?.publicData?.favourites ?? [];

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const unitType =
      publicData.mainCategory === 'sleep' ? config.bookingUnitTypeNight : config.bookingUnitTypeDay;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer currentPage="ListingPage" />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();

      let pictureIndex = parseInt(e.target.getAttribute('slideindex'));

      this.setState({
        imageCarouselOpen: true,
        currentImageIndex: pictureIndex,
      });
    };

    const handleViewPhotosButtonClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();

      this.setState({
        imageCarouselOpen: true,
        currentImageIndex: 0,
      });
    };

    // const handleViewPhotosClick = e => {
    //   // Stop event from bubbling up to prevent image click handler
    //   // trying to open the carousel as well.
    //   e.stopPropagation();
    //   this.setState({
    //     imageCarouselOpen: true,
    //   });
    // };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ProfilePage"
        params={{ id: ensuredAuthor.id.uuid }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    // const amenityOptions = findOptionsForSelectFilter('amenities', filterConfig);
    const { typesOptions } = config.custom;

    const type =
      publicData && publicData.type ? (
        <span>{categoryLabel(typesOptions, publicData.type, intl)}</span>
      ) : null;

    const { CAT_SLEEP } = config.custom;
    const isFavourite = favourites.find(favourite => favourite === listingId.uuid);

    const handleAddToFavourites = () => {
      const id = listingId.uuid;

      if (favourites.includes(id)) {
        favourites = favourites.filter(favourite => favourite !== id);
      } else {
        favourites.push(id);
      }
      onAddToFavourites(favourites);
    };

    let reviewsTotalRating = reviews.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.attributes.rating;
    }, 0);
    const reviewsAverageRating =
      reviewsTotalRating && reviews ? Math.round(reviewsTotalRating / reviews.length) : null;

    const listingsSuggestions =
      !!listings?.length && listings.filter(l => l?.id?.uuid !== currentListing?.id?.uuid);

    const handleFetchTransactionLineItems = additionServices => {
      if (Object.keys(bookingData).length) {
        onFetchTransactionLineItems({
          bookingData,
          listingId,
          isOwnListing,
          guests,
          additionServices,
        });
      }
    };

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.wrapper}>
              <SectionImages
                title={title}
                listing={currentListing}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingType,
                  tab: listingTab,
                }}
                imageCarouselOpen={this.state.imageCarouselOpen}
                onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                handleViewPhotosClick={handleViewPhotosClick}
                handleViewPhotosButtonClick={handleViewPhotosButtonClick}
                onManageDisableScrolling={onManageDisableScrolling}
                isMobileLayout={isMobileLayout}
                publicData={publicData}
                currentImageIndex={this.state.currentImageIndex}
              />
              <div className={css.contentContainer}>
                <div className={css.mainContent}>
                  <SectionHeading
                    priceTitle={priceTitle}
                    formattedPrice={formattedPrice}
                    richTitle={richTitle}
                    type={type}
                    hostLink={hostLink}
                    showContactUser={showContactUser}
                    onContactUser={this.onContactUser}
                    publicData={publicData}
                    user={currentAuthor}
                    params={params}
                    reviews={reviews}
                    isFavourite={!!isFavourite}
                    handleAddToFavourites={handleAddToFavourites}
                    reviewsAverageRating={reviewsAverageRating}
                    currentUser={currentUser}
                    intl={intl}
                  />
                  {/* <SectionWeekendPriceMaybe listing={currentListing} intl={intl} /> */}
                  <SectionDescriptionMaybe description={description} />
                  <SectionLanguagesMaybe publicData={publicData} intl={intl} />
                  <SectionFeaturesMaybe publicData={publicData} intl={intl} />
                  <SectionAdditionalServices
                    publicData={publicData}
                    intl={intl}
                    handleFetchTransactionLineItems={handleFetchTransactionLineItems}
                    selectedServices={additionServices}
                  />

                  <SectionMapMaybe
                    location={location}
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                    listingMainCategory={publicData?.mainCategory || CAT_SLEEP}
                    currentListing={currentListing}
                    listings={listings}
                    intl={intl}
                    listingsforMap={listingsforMap}
                  />
                  <SectionRulesMaybe publicData={publicData} />
                  <SectionReviews
                    reviews={reviews}
                    fetchReviewsError={fetchReviewsError}
                    reviewsAverageRating={reviewsAverageRating}
                  />
                  <SectionSustainablePractices publicData={publicData} />
                  <SectionSuggestions
                    listings={listingsSuggestions}
                    publicData={publicData}
                    intl={intl}
                    currentListing={currentListing}
                    // currentBrowserGeolocation={this.state.currentBrowserGeolocation}
                    parentListingGeolocation={currentListing?.attributes?.geolocation}
                    suggestionsTab={this.state.suggestionsTab}
                    setActiveTab={this.onSuggestionsTabHandle}
                    getAllListings={this.props.getAllListings}
                  />
                  {/* <SectionHostMaybe
                    title={title}
                    listing={currentListing}
                    authorDisplayName={authorDisplayName}
                    onContactUser={this.onContactUser}
                    isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                    onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                    sendEnquiryError={sendEnquiryError}
                    sendEnquiryInProgress={sendEnquiryInProgress}
                    onSubmitEnquiry={this.onSubmitEnquiry}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  /> */}
                </div>
                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  additionServices={additionServices}
                  totalPriceLineItems={totalPriceLineItems}
                />
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  // unitType: config.bookingUnitType,
  // unitType: oneOf([propTypes.bookingUnitTypeDay, propTypes.bookingUnitTypeNight]).isRequired,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // unitType: propTypes.bookingUnitType,
  // unitType: oneOf([propTypes.bookingUnitTypeDay, propTypes.bookingUnitTypeNight]).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  onAddToFavourites: func.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    listings,
    listingsforMap,
    bookingData,
    guests,
    additionServices,
    totalPriceLineItems,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    listings,
    listingsforMap,
    bookingData,
    guests,
    additionServices,
    totalPriceLineItems,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (
    bookingData,
    listingId,
    isOwnListing,
    guests,
    additionServices,
  ) =>
    dispatch(
      fetchTransactionLineItems(
        bookingData,
        listingId,
        isOwnListing,
        guests,
        additionServices,
      )
    ),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  getAllListings: category => dispatch(getAllListings(category)),
  getAllListingsForMap: () => dispatch(getAllListingsForMap()),
  onAddToFavourites: favourites => dispatch(addToFavourites(favourites)),
  onClearBookingData: bookingData => dispatch(clearBookingData(bookingData)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
