import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { IconReviewStar } from '../../components';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import config from '../../config';
import SectionAvatar from './SectionAvatar';
import AddToFavourites from './AddToFavourites';
import ShareListing from './ShareListing';
import classNames from 'classnames';
import { languagesHelper as language } from '../../helpers/languages';
import css from './ListingPage.module.css';

const categoryLabel = (categories, key, intl) => {
  const cat = categories.find(c => c.key === key);
  return cat ? language.labelsTranslator(cat.key, intl) : key;
};

const SectionHeading = props => {
  const {
    // priceTitle,
    // formattedPrice,
    richTitle,
    type,
    hostLink,
    // showContactUser,
    // onContactUser,
    user,
    params,
    handleAddToFavourites,
    isFavourite,
    publicData,
    reviews,
    reviewsAverageRating,
    currentUser,
    intl
  } = props;

  // const unitType = config.bookingUnitType;
  // const isNightly = unitType === LINE_ITEM_NIGHT;
  // const isDaily = unitType === LINE_ITEM_DAY;

  const categorySelected = publicData?.mainCategory;
  const {
    CAT_RENT,
    CAT_ACTIVITIES,
    categoriesAllList
  } = config.custom

  const listingCategory = publicData?.category

  const categoryClasses = classNames(
    css.category,
    { [css.categoryRent]: categorySelected === CAT_RENT, },
    { [css.categoryActivities]: categorySelected === CAT_ACTIVITIES, }
  )


  return (
    <div className={css.section}>
      <div className={css.titleContainer}>
        <h1 className={css.title}>
          {!!categorySelected && (
            <div className={categoryClasses}>
              {/* {optionLabel(categoriesAllList, categorySelected)} */}
              {categorySelected}
            </div>
          )}
          <span>
            {richTitle}
            {listingCategory && (
              <span className={css.subTitle}>
                , {categoryLabel(categoriesAllList, publicData.category, intl)}
              </span>
            )}
          </span>
        </h1>
        <div className={css.shareAndFavorites}>
          <ShareListing />
          {currentUser && <AddToFavourites handleAddToFavourites={handleAddToFavourites} isFavourite={isFavourite} />}
        </div>
      </div>
      <div className={css.authorInfo}>
        <div className={css.authorInfoItem}>
          <span className={css.authorInfoLabel}>
            <FormattedMessage id="ListingPage.hostedBy" />
          </span>
          <SectionAvatar user={user} params={params} />
          {hostLink}
        </div>

        {!!type && (
          <span className={css.authorInfoItem}>
            <span className={css.separator}>•</span>
            <span className={css.authorInfoLabel}>
              <FormattedMessage id="ListingPage.typeLabel" />
            </span>
            {type}
          </span>
        )}

        {!!reviews?.length && (
          <div className={css.authorInfoItem}>
            <span className={css.separator}>•</span>
            <IconReviewStar isFilled />
            <span className={css.reviewInfoRating}>
              {reviewsAverageRating}
            </span>
            <span className={css.reviewInfoTotal}>
              {reviews?.length === 1 ? (
                <FormattedMessage id="ListingPage.reviewCounter" values={{ reviewsCounter: reviews?.length }} />
              ) : (
                <FormattedMessage id="ListingPage.reviewsCounter" values={{ reviewsCounter: reviews?.length }} />
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionHeading;
