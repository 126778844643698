import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSliderArrowBig, ListingCardVertical } from '../../components';
import Slider from "react-slick";
import classNames from 'classnames';
import config from '../../config';
import css from './SectionSuggestions.module.css';



const SectionSuggestions = props => {
  const {
    className,
    rootClassName,
    publicData,
    intl,
    listings,
    currentListing,
    // currentBrowserGeolocation,
    parentListingGeolocation,
    suggestionsTab,
    setActiveTab,
    getAllListings
  } = props;

  if (!listings?.length) {
    return null
  }

  const { CAT_SLEEP, CAT_RENT, CAT_ACTIVITIES } = config.custom

  // let listingsForSlider

  // switch (suggestionsTab) {
  //   // case CAT_SLEEP:
  //   //   listingsForSlider = listings.filter(l => l.attributes.publicData.mainCategory === CAT_SLEEP)
  //   //   break;
  //   // case CAT_RENT:
  //   //   listingsForSlider = listings.filter(l => l.attributes.publicData.mainCategory === CAT_RENT)
  //   //   break;
  //   // case CAT_ACTIVITIES:
  //   //   listingsForSlider = listings.filter(l => l.attributes.publicData.mainCategory === CAT_ACTIVITIES)
  //   //   break;
  //   default:
  //     listingsForSlider = listings
  //     break;
  // }

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: listings?.length < 3 ? 1 : 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    nextArrow: <IconSliderArrowBig direction="next" />,
    prevArrow: <IconSliderArrowBig />,

    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: listings?.length < 3 ? 1 : 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className={css.root}>
      <div className={css.tabs}>
        <span
          className={classNames(css.tabsItem, { [css.tabsItemActive]: suggestionsTab === CAT_SLEEP })}
          onClick={() => {getAllListings(CAT_SLEEP), setActiveTab(CAT_SLEEP)}}
        >
          <FormattedMessage id="SectionSuggestions.altOptions" />
        </span>
        <span
          className={classNames(css.tabsItem, { [css.tabsItemActive]: suggestionsTab === CAT_RENT })}
          onClick={() => {getAllListings(CAT_RENT), setActiveTab(CAT_RENT)}}
        >
          <FormattedMessage id="SectionSuggestions.rentalsInArea" />
        </span>
        <span
          className={classNames(css.tabsItem, { [css.tabsItemActive]: suggestionsTab === CAT_ACTIVITIES })}
          onClick={() => {getAllListings(CAT_ACTIVITIES), setActiveTab(CAT_ACTIVITIES)}}
        >
          <FormattedMessage id="SectionSuggestions.activitiesInArea" />
        </span>
      </div>
      <div className={css.slider}>

        {!!listings?.length ? (
          <Slider {...sliderSettings}>
            {listings.slice(0,6).map(l => (
              <div className={css.sliderItem} key={l.id.uuid}>
                <ListingCardVertical
                  className={css.listingCard}
                  listing={l}
                  listingAmenities={l.attributes.publicData.extrasAmenities}
                  // currentBrowserGeolocation={currentBrowserGeolocation}
                  parentListingGeolocation={parentListingGeolocation}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <div className={css.noResults}>
            <h3 className={css.noResults}>
              <FormattedMessage id="SectionSuggestions.noResults" />
            </h3>
          </div>
        )}

      </div>
    </div>
  );
};

SectionSuggestions.defaultProps = { className: null, rootClassName: null };

SectionSuggestions.propTypes = {
  className: string,
  rootClassName: string,
};

export default SectionSuggestions;
